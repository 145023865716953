import wrapWithProvider from './wrap-with-provider'
import { styleID } from './src/styles/theme'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import './src/styles/app.global.scss'

export const wrapPageElement = wrapWithProvider

export const onInitialClientRender = () => {
  // eslint-disable-next-line no-undef
  const ssStyles = window.document.getElementById(styleID)
  ssStyles && ssStyles.parentNode.removeChild(ssStyles)
}
