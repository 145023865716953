import React from 'react'
import { Provider } from 'react-redux'

import createStore from './src/state/createStore'

const store = createStore()

// Page routes that should use Redux
const reduxPages = [/\/trial/g, /\/trial\/welcome/g]

// eslint-disable-next-line react/display-name,react/prop-types
export default ({ element, props }) => {
  // Path usually begins with a slash, e.g., '/customers', '/trial'
  let { path } = props

  // Since all of our regex matches are lower-cased, make sure the path is lower-cased.
  // This normalizes the case where the user navigates to '/TRIAL'.
  path = path.toLowerCase()

  const shouldUseRedux = reduxPages.some(r => path.match(r) !== null)
  return shouldUseRedux ? <Provider store={store}>{element}</Provider> : element
}
